import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { discountCodeDownloadCsvButton_DiscountActionFragment$key } from "@relay/discountCodeDownloadCsvButton_DiscountActionFragment.graphql";

import { DISCOUNT_ACTION_FRAGMENT } from "./discount-code-download-csv-button.graphql";
import { DiscountCodeDownloadCsvButtonProps } from "./discount-code-download-csv-button.props";

export const DiscountCodeDownloadCsvButton = ({
	discountActionFragmentRef,
}: DiscountCodeDownloadCsvButtonProps) => {
	const discountAction = useFragment<discountCodeDownloadCsvButton_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);
	const toast = useRef<any>(null);
	const downloadCsv = () => {
		if (discountAction.discountCodesCSV) {
			const link = document.createElement("a");
			link.href = "data:text/csv;charset=utf-8," + discountAction.discountCodesCSV;
			link.download = "Codes.csv";
			link.click();
		} else {
			toast.current?.show({
				severity: "success",
				life: 3000,
				summary: "CSV erstellt",
				detail: "Dein CSV wurde erfolgreich erstellt und heruntergeladen",
			});
		}
	};

	return (
		<>
			<Toast ref={toast} />
			<Button label="CSV" icon={EpicIcons.DOWNLOAD} onClick={downloadCsv} />
		</>
	);
};
