import { type PathParams } from "@thekeytechnology/epic-ui";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { BaseScreen } from "@components/base-screen";
import { Card } from "@components/card";
import { NoAccess } from "@components/no-access";
import { OrdersTable } from "@features/orders/orders-table/orders-table.component";
import { AccountsForUserTable } from "@features/users/accounts-for-users-table";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type userEditScreen_Query } from "@relay/userEditScreen_Query.graphql";
import { type UsersPath } from "@screens/users";
import { H1Span } from "@themes/font-tags";
import { EditUserForm } from "./parts/edit-user-form";
import { ResendActivationMailButton } from "./parts/resend-activation-mail-button";
import { ResetPasswordButton } from "./parts/reset-password-button";
import { UserRootNodes } from "./parts/user-root-nodes/user-root-nodes.component";
import {
	UserRootNodesFilter,
	UserRootNodesFilterContextProvider,
} from "./parts/user-root-nodes-filter";
import { USER_QUERY } from "./user-edit.graphql";
import {
	formsWrapperClass,
	formsWrapperRightClass,
	userActionsWrapperClass,
	userRootNodesContentWrapperClass,
	userRootNodesHeaderWrapperClass,
	wrapperClass,
} from "./user-edit.styles";

export const UserEditScreen = () => {
	const { userId } = useParams<PathParams<typeof UsersPath>>();
	const query = useLazyLoadQuery<userEditScreen_Query>(USER_QUERY, {
		id: userId || "",
		skip: !userId,
	});

	const canReadOrders = useHasPermissions(["UserInAccountPermission_OrderAdmin_Read"]);
	const canModifyOrders = useHasPermissions(["UserInAccountPermission_OrderAdmin_Modify"]);
	const canReadAuth = useHasPermissions(["UserInAccountPermission_AuthAdmin_Read"]);
	const canModifyAuth = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);

	if (!canReadAuth || !canModifyAuth) return <NoAccess />;

	return (
		<UserRootNodesFilterContextProvider>
			<BaseScreen title={`Benutzer bearbeiten: ${query.node?.name}`}>
				<div className={wrapperClass}>
					<div className={formsWrapperClass}>
						<Card title="Daten">
							<EditUserForm userFragmentRef={query.node} />
						</Card>
						<div className={formsWrapperRightClass}>
							<Card title="Aktionen">
								<div className={userActionsWrapperClass}>
									<ResendActivationMailButton userId={userId!} />
									<ResetPasswordButton userId={userId!} />
									<ConfirmDialog />
								</div>
							</Card>
							<Card
								header={
									<div className={userRootNodesHeaderWrapperClass}>
										<H1Span>Kurse verwalten</H1Span>
										<UserRootNodesFilter />
									</div>
								}
								pt={{
									body: {
										className: userRootNodesContentWrapperClass,
									},
									content: {
										className: userRootNodesContentWrapperClass,
									},
								}}
							>
								<UserRootNodes userId={userId!} queryFragmentRef={query} />
							</Card>
						</div>
					</div>
					{query.node && (
						<Card title="Konten">
							<AccountsForUserTable userRef={query.node} />
						</Card>
					)}
					{canReadOrders && (
						<Card title="Bestellungen">
							<OrdersTable
								ordersFragmentRef={query}
								ordersPerPage={10}
								enableFilter={false}
								canModifyOrders={canModifyOrders}
							/>
						</Card>
					)}
				</div>
			</BaseScreen>
		</UserRootNodesFilterContextProvider>
	);
};
