import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment licenseProductForm_QueryFragment on Query {
		...selectLicenseDefinitionField_QueryFragment @arguments(dataKinds: [Tree, AllTrees])
	}
`;

export const LICENSE_PRODUCT_FRAGMENT = graphql`
	fragment licenseProductForm_LicenseProductFragment on Product {
		id
		title
		isHidden
		isTaxFree
		netPrice
		data {
			... on LicenseProductData {
				licenseDefinitionAmount
				licenseDefinition {
					id
					data {
						name
					}
				}
			}
		}
	}
`;

export const EDIT_PRODUCT_MUTATION = graphql`
	mutation licenseProductForm_EditProductMutation($input: EditProductInput!) {
		Admin {
			Billing {
				editProduct(input: $input) {
					editedProduct {
						...licenseProductForm_LicenseProductFragment
					}
				}
			}
		}
	}
`;

export const EDIT_LICENSE_PRODUCT_MUTATION = graphql`
	mutation licenseProductForm_EditLicenseProductMutation($input: EditLicenseProductInput!) {
		Admin {
			Billing {
				editLicenseProduct(input: $input) {
					product {
						...licenseProductForm_LicenseProductFragment
					}
				}
			}
		}
	}
`;
