/**
 * @generated SignedSource<<8fdef36c00f9ecb1ea39cdcde458b042>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseProductForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectLicenseDefinitionField_QueryFragment">;
  readonly " $fragmentType": "licenseProductForm_QueryFragment";
};
export type licenseProductForm_QueryFragment$key = {
  readonly " $data"?: licenseProductForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseProductForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licenseProductForm_QueryFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "dataKinds",
          "value": [
            "Tree",
            "AllTrees"
          ]
        }
      ],
      "kind": "FragmentSpread",
      "name": "selectLicenseDefinitionField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "12e9bd686819523b0ad8469953e96eb2";

export default node;
