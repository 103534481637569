/**
 * @generated SignedSource<<7e87ddd85ca9e92a2475da6a98a4fc74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createLicensesForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectLicenseDefinitionField_QueryFragment">;
  readonly " $fragmentType": "createLicensesForm_QueryFragment";
};
export type createLicensesForm_QueryFragment$key = {
  readonly " $data"?: createLicensesForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createLicensesForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createLicensesForm_QueryFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "dataKinds",
          "value": [
            "Tree",
            "AllTrees"
          ]
        }
      ],
      "kind": "FragmentSpread",
      "name": "selectLicenseDefinitionField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c4b06e1b631504484287413ac7f6ee10";

export default node;
