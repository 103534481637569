import { css, cva } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({});

export const headerWrapperClass = css({
	zIndex: "10",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	width: "full",
	borderRadius: "12",
	backgroundColor: "shade.5",
	paddingX: "16",
	paddingY: "8",
});

export const headerClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
});

export const expandButtonClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "32",
		height: "32",
	},
	variants: {
		disabled: {
			true: {
				opacity: "0.3",
				cursor: "not-allowed",
			},
		},
	},
});

export const headerInnerClass = css({
	display: "flex",
	alignItems: "center",
	gap: "12",
});

export const lockStateClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "32",
		height: "32",
		borderRadius: "full",
		border: "1px solid",
	},
	variants: {
		state: {
			locked: {
				borderColor: "error.20",
				backgroundColor: "error.0",
			},
			unlocked: {
				borderColor: "success.20",
				backgroundColor: "success.0",
			},
			finished: {
				borderColor: "info.20",
				backgroundColor: "info.0",
			},
			paywallUnlocked: {
				borderColor: "info.20",
				backgroundColor: "info.0",
			},
		},
	},
});

export const contentWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});

export const contentHeaderClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
	paddingY: "8",
	paddingX: "16",
	paddingTop: "20",
	background: "shade.10",
	borderBottomRadius: "12",
	marginTop: "-24",
});

export const contentHeaderListClass = css({
	width: "full",
	display: "grid",
	gridTemplateColumns: "[250px 250px 1fr]",
	gap: "16",
});
