import { Icon, Tooltip, EpicIcons } from "@thekeytechnology/epic-ui";
import { useState } from "react";
import { useMutation } from "react-relay";
import { ConfirmDialog } from "@components/confirm-dialog";
import { formatDateTime } from "@components/DateTimeDisplay";
import type { userRootNodeItemTreeItem_SetTreeStateHeadMutation } from "@relay/userRootNodeItemTreeItem_SetTreeStateHeadMutation.graphql";
import { colorSuccess100Class, colorWarning100Class } from "@themes/color-classes";
import { P2Span, P3Span } from "@themes/font-tags";
import { SET_TREE_STATE_HEAD_MUTATION } from "./user-root-node-item-tree-item.graphql";
import {
	setCurrentHeadContentButtonClass,
	treeItemClass,
	treeItemModificationsClass,
	treeItemTitleClass,
} from "./user-root-node-item-tree-item.styles";
import { UserRootNodeItemTreeItemProps } from "./user-root-node-item-tree-item.types";
import { treeWrapperClass } from "../user-root-node-item-tree/user-root-node-item-tree.styles";
import { learnOpportunityV2IdToTreeNodeId } from "../user-root-node-item-tree/user-root-node-item-tree.utils";

export const UserRootNodeItemTreeItem = ({
	rootId,
	userId,
	item,
	hasPaywall,
	currentHeadContentId,
	submissions,
	depth = 0,
}: UserRootNodeItemTreeItemProps) => {
	const [expanded, setExpanded] = useState(depth === 0);
	const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);

	const isPaywallBarrier = hasPaywall && item.isPaywall;
	const isCurrentTreeState = item.data?.id === currentHeadContentId;
	const hasModifications = isPaywallBarrier || isCurrentTreeState;
	const isContentNode = item.data?.typeDefinition.definitionType === "content";
	const submission = submissions?.find(
		(s) => learnOpportunityV2IdToTreeNodeId(s.learnOpportunity?.id ?? "") === item.data?.id,
	);
	const tooltipId = `tooltip-${item.id.replace("=", "")}`;

	const isContentBeforePaywall = !isPaywallBarrier && !item.isAfterPaywall && isContentNode;
	const isContentWithSubmissionBeforePaywall = isContentBeforePaywall && Boolean(submission);
	const isContentNotCurrentBeforePaywall = depth > 1 && !isCurrentTreeState;

	const [setTreeStateHead] = useMutation<userRootNodeItemTreeItem_SetTreeStateHeadMutation>(
		SET_TREE_STATE_HEAD_MUTATION,
	);

	const handleSetNodeAsHeadContentOnClick = () => {
		if (item.isCurrentHeadOrAfterCurrentHead) {
			handleSetNodeAsHeadContentOnConfirm();
			return;
		}
		setConfirmationDialogVisible(true);
	};

	const handleSetNodeAsHeadContentOnCancel = () => {
		setConfirmationDialogVisible(false);
	};

	const handleSetNodeAsHeadContentOnConfirm = () => {
		setTreeStateHead({
			variables: {
				input: {
					rootId,
					userId,
					newHeadContentId: item.id,
				},
			},
		});
		setConfirmationDialogVisible(false);
	};

	return (
		<>
			<ConfirmDialog
				title="Position vor die aktuellen Position setzen"
				content={
					<div>
						<P2Span>
							Möchtest du wirklich die Position vor die aktuelle Position setzen?
						</P2Span>
						<br />
						<P2Span>Dies kann unerwünschte Nebeneffekte auslösen:</P2Span>
						<ul>
							<li>
								<P3Span>
									- Der Nutzer setzt nach Abschluss der neuen Position die
									Lektion/das Modul an der alten Position fort.
								</P3Span>
							</li>
						</ul>
					</div>
				}
				onCancel={handleSetNodeAsHeadContentOnCancel}
				onConfirm={handleSetNodeAsHeadContentOnConfirm}
				showDialog={confirmationDialogVisible}
			/>
			<div
				className={treeItemClass({
					hasIndentation: depth > 0,
				})}
			>
				<div
					className={treeItemTitleClass({
						isPaywallBarrier,
						isCurrentTreeState,
						isContentNode,
					})}
				>
					{!isContentNode && (
						<button
							type="button"
							onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
						>
							<Icon icon={expanded ? EpicIcons.ANGLE_DOWN : EpicIcons.ANGLE_RIGHT} />
						</button>
					)}
					{hasModifications && (
						<div className={treeItemModificationsClass}>
							{isPaywallBarrier && (
								<P3Span className={colorWarning100Class}>Paywall</P3Span>
							)}
							{isCurrentTreeState && (
								<P3Span className={colorSuccess100Class}>Aktuell</P3Span>
							)}
						</div>
					)}
					<P2Span>{item.data?.structureDefinition?.title}</P2Span>
					{isContentWithSubmissionBeforePaywall && (
						<div>
							<Tooltip
								target={`#${tooltipId}`}
								content={`Erstes Update: ${formatDateTime(
									submission?.startedAt ?? "",
								)}
Letztes Update: ${formatDateTime(submission?.lastUpdated ?? "")}`}
							/>
							<div id={tooltipId}>
								<Icon icon={EpicIcons.INFO_CIRCLE} />
							</div>
						</div>
					)}
					{isContentNotCurrentBeforePaywall && (
						<button
							type="button"
							onClick={handleSetNodeAsHeadContentOnClick}
							className={setCurrentHeadContentButtonClass}
						>
							<P3Span>Als aktuelle Position setzen</P3Span>
						</button>
					)}
				</div>
				{expanded && item.children.length > 0 && (
					<div className={treeWrapperClass}>
						{item.children.map((child) => (
							<UserRootNodeItemTreeItem
								key={child.id}
								rootId={rootId}
								userId={userId}
								item={child}
								hasPaywall={hasPaywall}
								currentHeadContentId={currentHeadContentId}
								submissions={submissions}
								depth={depth + 1}
							/>
						))}
					</div>
				)}
			</div>
		</>
	);
};
